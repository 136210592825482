import * as React from "react";
import type { HeadFC } from "gatsby";

import { Contact } from "../containers/contact/Contact";
import { Layout } from "../components/Layout";

const ContactPage = () => {
  return (
    <Layout>
      <Contact />
    </Layout>
  );
};

export default ContactPage;

export const Head: HeadFC = () => <title>Contact Us</title>;
