import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm } from "@formspree/react";
import { ChevronDown } from "react-feather";

import { Container } from "../../components/Grid";

const ScMain = styled.div`
  padding: 1px;
  font-size: 18px;
  padding-top: 4rem;
  padding-bottom: 2rem;

  @media screen and (min-width: 992px) {
    padding-top: 210px;
    padding-bottom: 180px;
  }
`;

const ScHeader = styled.div`
  margin-bottom: 2rem;
  @media screen and (min-width: 992px) {
    margin-bottom: 110px;
  }
`;

const ScTitle = styled.h1`
  font-size: 24px;
  text-align: center;

  @media screen and (min-width: 992px) {
    font-size: 44px;
  }
`;

const ScHelper = styled.div`
  font-size: 18px;
  color: #868f97;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  line-height: 26px;
  color: #868f97;
`;

const ScPanel = styled.div`
  @media screen and (min-width: 992px) {
    display: flex;
  }
`;
const ScPanelLeft = styled.div`
  @media screen and (min-width: 992px) {
    flex: 3;
    margin-right: 48px;
  }
`;
const ScPanelRight = styled.div`
  @media screen and (min-width: 992px) {
    margin-bottom: 2rem;
    flex: 2;
  }
`;

const ScForm = styled.form`
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 12px;
  max-width: 560px;
  margin-left: auto;

  @media screen and (min-width: 992px) {
    padding: 28px;
  }
`;
const ScFormRow = styled.div`
  margin-bottom: 12px;

  @media screen and (min-width: 992px) {
    display: flex;
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: 24px;
  }
`;
const ScSimpleRow = styled.div`
  margin-bottom: 12px;
`;
const ScFormGroup = styled.div`
  margin-bottom: 12px;

  @media screen and (min-width: 992px) {
    margin-bottom: 0;
    margin-left: 12px;
    margin-right: 12px;
    width: calc(50% - 24px);
  }
`;
const ScLabel = styled.label`
  display: block;
  font-size: 12px;
  line-height: 1.4;
  color: #ffffff;
  margin-bottom: 8px;
`;
const ScInput = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  background: transparent;
  color: white;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;

  &:focus {
    outline: none;
  }
`;
const ScSelectWrapper = styled.div`
  position: relative;
`;
const ScSelectIcon = styled.div`
  color: white;
  right: 12px;
  top: 6px;
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
`;
const ScSelect = styled.select`
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  background: transparent;
  outline: none;
  color: white;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  appearance: none;
`;
const ScTextarea = styled.textarea`
  width: 100%;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  background: transparent;
  color: white;
  padding: 12px;
  height: 156px;
  font-size: 14px;
  resize: none;

  &:focus {
    outline: none;
  }
`;

const ScButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ScContact = styled.div`
  padding: 12px;

  h6 {
    color: white;
    font-size: 16px;
    margin-bottom: 8px;
  }

  @media screen and (min-width: 992px) {
    padding: 12px 28px;
    margin-bottom: 6rem;
    margin-top: 80px;
  }
`;
const ScContactAddress = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  color: #868f97;
  border-bottom: 1px solid rgba(255, 255, 255, 0.28);
  padding-bottom: 8px;
  margin-top: 0;
  margin-bottom: 8px;
  display: inline-block;
`;
const ScContactPhone = styled.a`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  color: #868f97;
  display: block;
`;

const ScFooter = styled.div``;
const ScMessage = styled.p`
  font-size: 16px;
  color: #b4f002;
`;

const ScWhiteButton = styled.button`
  display: inline-block;
  background: white;
  border-radius: 2px;
  text-align: center;
  font-size: 14px;
  color: #0b0b0f;
  font-weight: bold;
  transition: all 0.3s;
  min-width: 120px;
  height: 40px;
  border-radius: 20px;
  padding-left: 24px;
  padding-right: 24px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: #0b0b0f;
    opacity: 0.75;
    text-decoration: none;
  }

  svg {
    margin-left: 8px;
  }
`;

export const Contact = () => {
  const [state, handleSubmit] = useForm("mbjezgoq");
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [website, setWebsite] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [details, setDetails] = useState<string>("");

  useEffect(() => {
    if (!state.succeeded) {
      return;
    }

    setName("");
    setEmail("");
    setWebsite("");
    setType("");
    setDetails("");
  }, [state]);

  return (
    <ScMain>
      <Container>
        <ScHeader>
          <ScTitle>Get in touch</ScTitle>
          <ScHelper>
            We'd love to hear from you! Don't hesitate to get in touch with us
            if you have any questions or if you'd like to learn more about how
            PowerTrade can help you.
          </ScHelper>
        </ScHeader>

        <ScPanel>
          <ScPanelLeft>
            <ScForm onSubmit={handleSubmit}>
              <ScFormRow>
                <ScFormGroup>
                  <ScLabel>Name</ScLabel>
                  <ScInput
                    type="text"
                    name="name"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </ScFormGroup>
                <ScFormGroup>
                  <ScLabel>Email</ScLabel>
                  <ScInput
                    type="email"
                    name="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </ScFormGroup>
              </ScFormRow>

              <ScFormRow>
                <ScFormGroup>
                  <ScLabel>Website</ScLabel>
                  <ScInput
                    type="text"
                    name="website"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                  />
                </ScFormGroup>
                <ScFormGroup>
                  <ScLabel>Type of request</ScLabel>
                  <ScSelectWrapper>
                    <ScSelect
                      name="type"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value="dex">PowerDEX</option>
                      <option value="partnership">Partnership</option>
                      <option value="investment">Investment</option>
                      <option value="bug-bounty">Bug Bounty</option>
                    </ScSelect>
                    <ScSelectIcon>
                      <ChevronDown size={12} />
                    </ScSelectIcon>
                  </ScSelectWrapper>
                </ScFormGroup>
              </ScFormRow>

              <ScSimpleRow>
                <ScLabel>How can we help you?</ScLabel>
                <ScTextarea
                  name="details"
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                />
              </ScSimpleRow>

              <ScFooter>
                {state.succeeded ? (
                  <ScMessage>
                    Thank you for getting in touch. Your message has been
                    submitted successfully!
                  </ScMessage>
                ) : null}
                <ScButtonWrapper>
                  <ScWhiteButton type="submit">
                    Contact Us{state.submitting ? "..." : ""}
                  </ScWhiteButton>
                </ScButtonWrapper>
              </ScFooter>
            </ScForm>
          </ScPanelLeft>

          <ScPanelRight>
            <ScContact>
              <h6>PowerTrade</h6>
              <ScContactPhone href="mailto:support@power.trade" target="_blank">
                support@power.trade
              </ScContactPhone>
            </ScContact>
          </ScPanelRight>
        </ScPanel>
      </Container>
    </ScMain>
  );
};
